.opcCode{
	display: flex;
}
.opcCode input{
	flex-grow: 1;
}
.lotes{
	flex-grow: 1;
	overflow-y: auto;
}
.productos{
	box-sizing: border-box;
}
.lotes,
.productos{
	display: flex;
	flex-direction: column;
}
.lotes>div,
.productos>div{
	display: flex;
}
.lotes>div>span,
.productos>div>span{
	flex-grow: 1;
	flex-basis: 0;
}
.lotes .encabezados,
.productos .encabezados{
	font-weight: bold;
	text-align: center;
}
.infoGeneral{
	display: flex;
	flex-direction: column;
}
.infoGeneral>div{
	display: flex;
}
.infoGeneral>div>span{
	flex-grow: 1;
	flex-basis: 0;
}
.infoGeneral>div>span>input{
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}
.nuevoLote{
	display: flex;
	flex-direction: column;
}
.nuevoLote>div{
	display: flex;
	justify-content: space-around;
}
.productos .minimo{
	background-color: #fff6c8!important;
}
.productos .agotado{
	background-color: lightpink!important;
	color: grey;
}
.productos.agotado .agotado{
	display: none;
}
.productos.minimo .minimo{
	display: none;
}
.productos.descontinuado .descontinuado{
	display: none;
}
.productos.normales>div[class=''],
.productos.normales>div:not([class]){
	display: none;
}
.filtros{
	display: flex;
	justify-content: space-around;
	padding: 1em;
}
.filtros>label>input{
	display: none;
}
.filtros>label>span{
	display: inline-block;
	padding: .5em;
	border-radius: 1em;
}
.lblNormales span{
	border: 2px solid cadetblue;
	background-color: white;
}
.lblNormales input:checked+span{
	background-color: cadetblue;
}
.lblMinimo span{
	border: 2px solid #fff6c8;
	background-color: white;
}
.lblDescontinuado span{
	border: 2px solid lightgrey;
	background-color: white;
}
.lblMinimo input:checked+span{
	background-color: #fff6c8;
}
.lblAgotado span{
	border: 2px solid lightpink;
	background-color: white;
}
.lblAgotado input:checked+span{
	background-color: lightpink;
}
.lblDescontinuado input:checked+span{
	background-color: lightgrey;
}
.tShadow,
.productos{
	box-shadow: 0 0 5px lightgray;
}
.bus{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1rem 0;
}