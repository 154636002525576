body{
  background-color: snow;
}
nav>.active{
  background-color: blue;
}

.contenedor{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.modal{
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-color: rgba(0, 0, 0, .4);
  z-index: 10;
}
.modal>div{
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 90vw;
  min-height: 80vh;
  min-width: 80vw;
  background-color: white;
  padding: 2em;
  box-sizing: border-box;
}
.botones{
  display: flex;
  justify-content: center;
}
.botones>div{  
  margin: 0.5rem;

}
.botones>span{
  cursor: pointer;
  min-width: 8em;
  text-align: center;
  padding: .5em;
  box-sizing: border-box;
}
.botones .sig{
  background-color: cornflowerblue;
  color: white;
}
.botones .cancel{
  color: red;
}
.navPrin{
    height: 4rem;
    background-color: white;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 2px solid red;
    border-top: 2px solid red;
}
.navPrin>div:first-child,
.navPrin>div:last-child{
  flex-basis: 0;
  flex-grow: 1;
}
.navPrin>div:last-child{
  display: flex;
  justify-content: flex-end;
}
.navPrin>div:nth-child(2){
  display: flex;
  flex-basis: 0;
  flex-grow: 8;
}
.navLink{
  padding: 0 1rem;
}
.navLink>a{
    text-decoration: none;
    padding: 0 1rem;
    color: red;
    width: 5rem;
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    margin-right: 2rem;
}
.navLink>a:hover{
  font-weight: bold;
}
.navLink>a.active{
  font-weight: bold;
}
.logoIMG{
  width: 7rem;
  margin-left:2rem ;
}
.imgIcono{
  width: 2rem;
}
.imgIconoCh{
  width: 1.5rem;
}
.imgIcono,
.imgIconoCh{
  cursor: pointer;
  margin: 0 0.5rem;
}
.imgIcono:hover,
.imgIconoCh:hover{
  filter: drop-shadow(0 0 0.5rem white);
}
.inputBasic{
  outline: none;
  border:0;
  border-bottom: 0.1rem solid gray;
  min-width: 7rem;
  font-size: 12pt;
  padding: 0.4rem;
  box-sizing: border-box;
  padding-bottom: 0!important;
}
.inputBasic:hover,
.inputBasic:focus{
  border-color: red;
}

.inputBasic:focus{
  box-shadow: 0 0 0.5rem darkgray;
}
.botonD{
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  width: max-content;
  height: max-content;
  border: 1px solid black;
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.botonD:hover{
  color: red;
  border-color: red;
}
.botonR{
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  width: max-content;
  height: max-content;
  text-decoration: none;
  color: white;
  cursor: pointer;
  background-color: red;
}

.botonR:hover{
  box-shadow: 0 0 5px gray;
}