.oferta{
	background-color: #d3ffde!important;
}
.modalPago{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: max-content!important;
	height: max-content!important;
	align-items: center;
}
.cambioTarjeta{
	display: flex;
	flex-direction: column;
	width: 100%;
}
.cambioTarjeta>div{
	display: flex;
	justify-content: space-around;
	padding-top: 3em;
}
.tipoPago{
	display: flex;
	justify-content: space-around;
}
.cambioTarjeta label>input+span,
.tipoPago>label>input+span{
	min-width: 10em;
	border: 2px solid red;
	padding: 1em;
	box-sizing: border-box;
	text-align: center;
}
.cambioTarjeta label:focus span{
	box-shadow: 0 0 5px red;
}
.cambioTarjeta label:focus,
.tipoPago>label:focus{
	outline: none;
}
.cambioTarjeta label>input:checked+span,
.tipoPago>label>input:checked+span{
	background-color: lightpink;
}
.cambioTarjeta label>input,
.tipoPago>label>input{
	display: none;
}
.prueba{
	position: relative;
	overflow: hidden;
}
.prueba input{
	background-color: transparent;
}
.prueba span{
	position: absolute;
	bottom: 0;
	left: 0;
	color: grey;
	width: 100%;
	z-index: -1;
	transition: all 1s;
}
.prueba input:focus+span{
	color: red;
	bottom: 100%;
}
.prueba input::placeholder{
	color: transparent;
}
.prueba input:not(:placeholder-shown)+span{
	bottom: 100%;
}
.imgProd{
	max-width: 5em;
	max-height: 5em;
}
.imgProd img{
	max-width: min(100%, 5em);
	max-height: min(100%, 5em);
}
.contTickets{
	display: flex;
	height: 100vh;
}
.contTickets>div:first-child{
	min-width: max(25em, 25vw);
}
.contTickets>div:last-child{
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}
.botones span:focus,
.tablaProductos div:focus{
	box-shadow: 0 0 10px hotpink;
	outline: 2px solid white;
}
.tablaProductos{
	flex-grow: 1;
	position: relative;
	padding-bottom: 4em;
}
.tablaProductos .encabezados.bottom{
	position: absolute;
	bottom: 1em;
	left: 0;
	font-weight: bold;
}
.tablaProductos,
.tablaTickets{
	display: flex;
	flex-direction: column;
}
.tablaProductos>div,
.tablaTickets>div{
	display: flex;
}
.tablaTickets>div>span:first-child{
	display: none;
}
.tablaProductos>div:nth-child(2n){
	background-color: rgba(0, 0, 0, .1);
}
.tablaProductos>div>span{
	display: flex;
	align-items: center;
}
.tablaProductos>div>span,
.tablaTickets>div>span{
	flex-grow: 1;
	flex-basis: 0;
}
.productosT{
	flex-grow: 1;
}
.ticketInfo{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 3em;
	box-sizing: border-box;
}
.infoTicket{
	display: flex;
}
.infoTicket>div:first-child{
	width: 10em;
}
.infoTicket>div:last-child{
	flex-grow: 1;
}
.infoTicket input[type='file']{
	display: none;
}
.infoGeneral .imgProducto{
	height: 10em;
	width: 10em;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.imgProducto input{
	display: none;
}
.infoGeneral img{
	max-width: 7em;
	max-height: 7em;
}
.infoTicket img{
	max-width: 100%;
	max-height: 100%;
}
.cambiaImg{
	width: 100%;
	text-align: center;
}
.contTickets{
	display: flex;
	height: 100vh;
	box-sizing: border-box;
	padding: 1rem;

}
.contTickets>div{
	box-sizing: border-box;
	border: 1px solid lightgrey;
	border-radius: 1rem;
	overflow-y: auto;
}
.contTickets>div:first-child{
	min-width: max(25em, 25vw);
	margin-right: 0.5rem;
	height: 50%;
}
.contTickets>div:last-child{
	flex-grow: 1;
	margin-left: 0.5rem;
}
.top{
	background-color: red;
	height: 3rem;
	padding: 1rem;
	box-sizing: border-box;
	border-radius: 1rem 1rem 0 0;
	margin-bottom: 1rem;
	text-align: center;
	font-size: 15pt;
	color: white;
	font-weight: 300;
}
.encabezados{
	background-color: lightpink!important;
}
.tablaProductos,
.tablaTickets,
.productos{
	display: flex;
	flex-direction: column;
	margin: 1rem;
}
.tablaProductos>div,
.tablaTickets>div,
.productos>div{
	display: flex;
	min-height: 2rem;
	border-bottom: 1px solid lightgrey;

}
.tablaTickets>div:nth-child(2n),
.tablaProductos>div:nth-child(2n),
.productos>div:nth-child(2n){
	background-color: mintcream;
}
.tablaProductos>div>span{
	display: flex;
	align-items: center;
}
.tablaProductos>div>span:nth-child(2){
	flex-grow: 2;
}
.tablaTickets>div>span:first-child{
	flex-grow: 3;
}
.tablaTickets>div>span:nth-child(2){
	flex-grow: 2;
}
.tablaProductos>div>span,
.tablaTickets>div>span,
.productos>div>span{
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.lvacio{
	text-align: center;
}
.cambio, .modalChico{
	max-height: 20rem!important;
    max-width: 20rem!important;
    min-height: 20rem!important;
    min-width: 20rem!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15pt;
}
.cambio>div:first-child{
	text-align: center;
}
.cambio input{
	text-align: center;
	margin:0.5rem 0 1rem 0;
}
.cantCambio{
	text-align: center;
	margin-bottom: 1rem;
}
.pCantidad>div:last-child{
	text-align: center;
}
.pCantidad input{
	margin: 1rem;
	text-align: center;
}
.busqueda>div:first-child{
	text-align: center;
	margin-bottom: 1rem;
}
.busqueda .inputBasic{
	margin: 0 1rem;
}
.busque{
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.busqueda .tablaProductos{
	overflow-y: auto;
}
.busqueda .tablaProductos>div{
	min-height: 3em;
}
.venT{
	position: static;
}
.venT>div:not(.bottom)>span:nth-child(1),
.venT>div:not(.bottom)>span:nth-child(2){
	display: none;
}
.venT .bottom{
	display: flex;
	flex-direction: column;
	width: 25rem;
	font-size: 1.5rem;
	bottom: 1rem!important;
	right: initial!important;
	left: 1rem!important;
	position: fixed!important;
	border-radius: 1rem;
}
.venT .bottom>span{
	flex: 1!important;
	text-align: center;
	display: flex;
	flex-direction: column;
}
.venT .bottom>span>div:last-child{
	font-size: 7rem;
	font-weight: 200;
}
.botMov{
	justify-content: flex-start;
	padding: 1rem;
	box-sizing: border-box;
}
.totTar{
	text-align: center;
}
.totTar>div:last-child{
	font-size: 2.5rem;
	margin: 0.5rem 0;
}
.cSaber{
	text-align: center;
	width: 100%;
}
.tipoPago{
	width: 100%;
}